/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/functions";

//@import "../../_vendor/bootstrap-5.0.0-beta1/scss/variables";
@import "partials/custom-variables";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/variables";

@import "../../_vendor/bootstrap-5.0.0-beta1/scss/mixins";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/utilities";

// Layout & components
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/root";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/reboot";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/type";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/images";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/containers";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/grid";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/tables";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/forms";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/buttons";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/transitions";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/dropdown";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/button-group";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/nav";

@import "../../_vendor/bootstrap-5.0.0-beta1/scss/navbar";
//@import "partials/navbar"

@import "../../_vendor/bootstrap-5.0.0-beta1/scss/card";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/accordion";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/breadcrumb";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/pagination";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/badge";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/alert";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/progress";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/list-group";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/close";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/toasts";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/modal";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/tooltip";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/popover";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/carousel";
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/spinners";

// Helpers
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/helpers";

// Utilities
@import "../../_vendor/bootstrap-5.0.0-beta1/scss/utilities/api";
// scss-docs-end import-stack
