html, body {
	height:100%;
}
html, body {
	height: 100%;
}
html {
  font-size: 14px;
}
body {
	display: flex;
	flex-direction: column;
}
img {
	@extend .img-fluid;
}
button * {
	pointer-events: none;
}

figure {
	display: table;
	figcaption {
		display: table-caption;
		caption-side: bottom;
		font-size: .85rem;
		padding: .6rem .5rem .7rem;
		border-radius: 1rem;
		border: 1px solid #3281b8;
		background: $light;
		&:not(.border-full) {
			border-radius: 0 0 1rem 1rem;
			border-top: none;
		}
	}
}

figure.highlight {
	display: block;
	pre {
		margin-bottom: 0;
	}
}

// Meant for custom SVG that we want to use as an icon in a link or something.
.svg-icon {
    height: 1rem;
    margin-top: -.2rem;
    display: inline;
}

.container {
	max-width: 800px;
}

.btn-light:hover {
	color: $black;
	background-color: darken($light, 10%);
	border-color: darken($light, 25%);
}


.content {
	flex: 1 0 auto;
	.container {
		p:last-child, div:last-child, figure:last-child {
			margin-bottom:0;
		}
	}
}

.main-header, .main-footer {
	flex-shrink: 0;
}

pre.lineno {
	margin-right: 0.5rem;
}

.bg-black {
	background-color: #000;
}

.navbar a {
	text-decoration: none;
}

// Turning the chevron around when a card toggle opens its content.
div.card[aria-expanded="true"] .card-header .fa-chevron-right {
	margin-top: .125rem;
	transform: rotate(90deg);
}

.max-width-medium {
	max-width:500px;
}

.embed-youtube {
	display: block;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	margin-bottom:$spacer;
	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.cp_embed_wrapper {
	margin-bottom: 1rem;
}


.post-thumb {
	img {
		max-height: 200px;
	}
}


/* WORDPRESS CLASSES */
/* ============================ */
.alignleft, .alignright {
	margin-right: auto;
	margin-left: auto;
}
.aligncenter {
	margin: 0 auto;
}
.alignleft, .alignright, .aligncenter {
	margin-bottom: $spacer;
	img {
		display: block;
		margin: 0 auto;
	}
}

//
ul.post-list{
	h2 > a {
		text-decoration: none;
	}
	li:nth-child(even) {
		background: $light;
		padding: .5rem;
		border: 1px solid $primary;
		border-radius: .5rem;
	}
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 16px;
  }
	.navbar-nav, .navbar-search-form input {
		font-size: .9rem;
	}
	.post-thumb {
		max-width: 25%;
		img {
			max-height: none;
		}

	}
}

@include media-breakpoint-up(md) {
	html:not(.stable-font-size) {
		font-size: 20px;
	}
	.alignleft, .alignright {
		max-width: 300px;
	}
	.alignleft {
		float:left;
		margin-right: $spacer;
	}
	.alignright {
		float:right;
		margin-left: $spacer;
	}
}

@include media-breakpoint-up(lg) {
  html:not(.stable-font-size) {
    font-size: 24px;
  }
}








