// ================================
// Custom Variables
// ================================

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:        #3281b8;
//$light-blue:  #e6f4ff;
$light-blue:  #cce7fd;
$indigo:      #6610f2;
$purple:      #6f42c1;
$pink:        #d63384;
$red:         #dc3545;
$orange:      #fd7e14;
$yellow:      #ffc107;
$green:       #198754;
$teal:        #20c997;
$cyan:        #0dcaf0;
$gold:        #e0a316;

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "light-blue": $light-blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800,
  "gold":         $gold,
);
// scss-docs-end colors-map

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $light-blue;
$warning:       $yellow;
$danger:        $red;
$light:         $light-blue;
$dark:          $gray-900;


$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .25);
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color:  rgba($white, .1);

$navbar-brand-margin-end:           .5rem;

