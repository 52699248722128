// Doing everything in the _sass folder.

@import 'local-bootstrap';

@import "syntax-highlight";

// Local site variables.
$baseurl: "/";

@import "partials/trans";
@import "partials/custom404";
@import "base";